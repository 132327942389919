import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import BonusChartEditor from "./form/BonusChartEditor.js" 
import InputText from "./form/InputText.js";
import { apiClient } from "../apiCall";
import Period from "./form/Period.js";
import { config } from "../config";

import {
    BrowserRouter as Router,
    StaticRouter, // for server rendering
    Route,
    Link
    // etc.
  } from "react-router-dom";

  export const defaultData = {
    
    period:null,
    data:[]
   
  };


export default function BonusList(props) {


  const { state, dispatch } = useStore();
  const apiCall = apiClient(state, dispatch);
  const [Data, setData] = useState({
    ...defaultData,
    period: state.common.current_period,
  });
  const apiResponse = (data) => {
    if (data) {
      setData({ ...Data, data: [ ...data ] });
    }
  };

   const changePeriod = (name, value, index) => {
      //props.onChange(name,value,index);
      setData({ ...Data, period: value });
  
  
  };
  
  useEffect(() => {
  
   
      if (
            Data.period !== null
      ) {
  
       
        let url =
          "/bonuslists/" + Data.period;
       
        fetch(config.apiUrl + url, {
          headers: new Headers({
            Authorization: "Bearer " + state.user.token,
          }),
        })
          .then((res) => res.json())
          .then((data) => apiResponse(data));
      }
    }, [Data.period]);
   


const getName=(bonus_id)=>{
console.log(state.common.bonuschart_alaiset,bonus_id);
    for(var i=0;i<state.common.bonuschart_alaiset.length;i++){
        
        if(parseInt(state.common.bonuschart_alaiset[i].id)==parseInt(bonus_id)){
        
            return state.common.bonuschart_alaiset[i].name;
        }
    }
    return null;
}
const getBonuses=(bonuses)=>{


   return bonuses.map((bonus_id,index)=>{

    return <span className="bonuslist">{getName(bonus_id)}{index+1!=bonuses.length&&", "}</span>

    });
}
     const period_object = (
        <Period
          onChange={changePeriod}
          name="period"
          value={Data.period}
          edit={true}
        />
      );
      const getPersonsWithBoss=(persons,boss_id)=>{

       return persons.map((person)=>{
        console.log("rolee",person.role);
            if(person.role && person.role.boss_id==boss_id){
            return <h4><Link to={"/persons/"+person.id}>{person.lastname} {person.firstname}</Link>, <span>{person.role.name}</span> {getBonuses(person.role.bonuses)}
            {getPersonsWithBoss(persons,person.id)}
            </h4>
            }

        });



      };


      const companies = Data.data .map((company) => {
        return <div className="box box-bonuslist"><h2>{company.name}</h2>
            {getPersonsWithBoss(company.persons,null)}
        </div>
      });

      if(!state.common.bonuschart_alaiset){
        return null;
      }
   
    return   (<div className="mycontent">
    <div className="inner">
      <div className="topic-box">
        <div className="inner tapahtumat-selectors no-print">
          <h2>Bonushierarkia</h2>{period_object}
          </div></div>{companies}</div></div>)
}