import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import InputDouble from "./form/InputDouble.js";

import InputSelect from "./form/InputSelect.js";
import { apiClient } from "../apiCall";
import {format} from "../commonFunctions";

import SettingPair from "./form/SettingPair.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";


import TopNav from "./TopNav.js";
import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";



  export const defaultPankkiData={
  
    data:[],
    loading:true,
    orginal:null,
    showUser:null
};


export default function PankkiList(props) {


  const { state, dispatch } = useStore();

  const apiCall = apiClient(state, dispatch);	
 






const [PankkiData, setPankkiData] = useState({
    ...defaultPankkiData
    
});

useEffect(() => {
  let url="pankki";
 

  apiCall.GET_RAW(url, function(status,err_msgs=null,response=null){

	
    if(status==400){
  
      //setFolderData({...FolderData,errors:err_msgs})
    }else if(status==200){
   
        setPankkiData({...PankkiData,loading:false,showUser:null,data:[...response.data]})



    }




  });




  }, []);

  if(PankkiData.loading){
    return <div></div>
  }
  const formatPeriod=(p)=>{

   let ps= p.split("-");
   return ps[1]+"/"+ps[0];
  }
  const showPersondata=()=>{

    const itemsperson= PankkiData.showUser.tapahtumat.map((item,index) =>{

        return (<tr className="pankki-row data-row" key={index}>
          <td>{formatPeriod(item.period)}</td><td>{item.pankkiselite}</td>
          <td>{format(item.otto)} / {format(item.pano)} €</td>
          <td>{(item.otto_paivarahat)} / {(item.pano_paivarahat)} kpl</td>
          <td>{(item.otto_puolipaivarahat)} / {(item.pano_puolipaivarahat)} kpl</td>
          <td>{(item.otto_km)} / {(item.pano_km)} km</td></tr>);
      
      });



    return  <table class="table-new"><tbody><tr className="text-header">
      <th>Kausi</th><th>Selite</th><th>Otto / Pano €</th><th>Otto / Pano päiväraha</th><th>Otto / Pano ½ päiväraha</th><th>Otto / Pano kilometrit</th>
      
      </tr>{itemsperson}<tr className="data-row data-totals-pankki"><td></td>
      <td>Yhteensä</td>
      <td>{format(PankkiData.showUser.total.saldo)} €</td>
      <td>{(PankkiData.showUser.total.saldo_paivarahat)} kpl</td>
      <td>{(PankkiData.showUser.total.saldo_puolipaivarahat)} kpl</td>
      <td>{(PankkiData.showUser.total.saldo_km)} km</td>
      
      </tr></tbody></table>;

  };
  const showPersonAtIndex=(index)=>{


    setPankkiData({...PankkiData,showUser:PankkiData.data[index]});
  }

  const items= PankkiData.data.map((item,index) =>{

    return (<tr className="data-row pankki-row pankki-row-hover"  onClick={(e)=>showPersonAtIndex(index)}key={index}>
      <td>{item.person.lastname} {item.person.firstname}</td>
      <td><span className={item.total.saldo<0?"negative":""}>{format(item.total.saldo)} €</span></td>

      <td> <span className={item.total.saldo_paivarahat<0?"negative":""}>{(item.total.saldo_paivarahat)}</span> kpl</td>
     
      <td> <span className={item.total.saldo_puolipaivarahat<0?"negative":""}>{(item.total.saldo_puolipaivarahat)}</span> kpl</td>
      <td> <span className={item.total.saldo_km<0?"negative":""}>{(item.total.saldo_km)}</span> km</td>
     
     
      
      </tr>);
  
  });
      
  const back=()=>{
    setPankkiData({...PankkiData,showUser:null});

  }
  return (<div className='mycontent pankki'>
    
    <div className="inner">
      
      
      
    <div class="topic-box"><div class="inner">  {PankkiData.showUser!==null&& <h2>Pankki  / {PankkiData.showUser.person.lastname} {PankkiData.showUser.person.firstname}  <span className="sulje" onClick={back}>sulje</span></h2>}
    {PankkiData.showUser==null&& <h2>Pankki</h2>}</div></div>
      
      
      <div className="box">
  
    
    {PankkiData.showUser==null?
   <table class="table-new"><tbody><tr className="text-header">
    <th>Henkilö</th>
    <th>Saldo (€)</th>
    <th>Saldo (päiväraha)</th>
    <th>Saldo (puolipäiväraha)</th>
    <th>Saldo (kilometrit)</th>
    </tr>{items}</tbody></table>
    :showPersondata()}
   </div>
   </div>
  </div>);


}
