
import { useEffect, useRef } from "react";
import { useStore } from "../store/useStore";

export default function InActivity() {

    //const TIME_OUT=180000;
        const TIME_OUT=180000;

    const timeoutRef = useRef(null);

        const { state, dispatch } = useStore();
    

    const onInactive=()=>{

       // alert("LOGAOUT");
       dispatch({ type: "LOGOUT" });
       dispatch({ type: "COMMON_LOGOUT" });
       dispatch({ type: "USER_LOGOUT" });

    };
    useEffect(() => {

        
      const resetTimer = () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          onInactive();
        }, TIME_OUT); // 3 minutes
      };
  
      const handleActivity = () => {

        
        resetTimer();
      };
  
      // Attach event listeners
      window.addEventListener("mousemove", handleActivity);
      window.addEventListener("keydown", handleActivity);
  
      // Start the initial timer
      resetTimer();
  
      return () => {
        // Cleanup event listeners and timeout on unmount
        window.removeEventListener("mousemove", handleActivity);
        window.removeEventListener("keydown", handleActivity);
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
      };
    }, [onInactive]);
  
    return null; // This component doesn’t render anything

  };

