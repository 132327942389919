import logo from './logo.svg';

import { useStore } from "./store/useStore";
import React, { useEffect, useState } from 'react';
import { apiClient } from "./apiCall";
import { StoreProvider } from "./store/useStore";
import LoginForm from "./components/LoginForm";
import Persons from "./components/Persons";
import Person from "./components/Person";
import PersonTaxes from "./components/PersonTaxes";
import PersonRoles from "./components/PersonRoles";
import Etusivu from "./components/Etusivu";
import BonusesAll  from "./components/BonusesAll";
import SideNavigation from "./components/SideNavigation";
import {BrowserRouter, Switch,Route} from "react-router-dom";
import Palkanlaskenta from './components/Palkanlaskenta';
import PalkanlaskentaLisa from './components/PalkanlaskentaLisa';
import PankkiList from './components/PankkiList';
import CreditCard from './components/CreditCard';
import Asetukset from './components/Asetukset';
import BonusList from './components/BonusList.js';
import OmatTiedot from './components/OmatTiedot';
import InActivity from './components/InActivity';

import BonusChartMyyjat from './components/BonusChartMyyjat';

import BonusChartOsasto from './components/BonusChartOsasto';
import BonusChartAlaiset from './components/BonusChartAlaiset';
import PersonSettings  from "./components/PersonSettings";
import PersonLoan  from "./components/PersonLoan";

import PersonNettoVahennykset from "./components/PersonNettoVahennykset";
import PersonAlaiset from "./components/PersonAlaiset";
import PersonBonuses  from "./components/PersonBonuses";
import PayWage from './components/PayWage';
import PAYWAGE_BACK from "./components/PAYWAGE_BACK";
import AddPerson from "./components/AddPerson.js";
import YritysLainatAll  from "./components/YritysLainatAll.js";
import BonusChartEditor from "./components/form/BonusChartEditor.js";
import Palkkalaskelmat from "./components/Palkkalaskelmat.js";
import KayttajaHallinta from "./components/KayttajaHallinta.js";
import KayttajaEditor from "./components/KayttajaEditor.js";
import Memo from "./components/Memo.js";

import PalkkalaskelmatHenkilot from "./components/PalkkalaskelmatHenkilot.js";
import Kayttajahallinta from './components/KayttajaHallinta';
import Tapahtumat from './components/Tapahtumat';

function App() {

  const { state, dispatch } = useStore();
  const apiCall = apiClient(state, dispatch);

  const fetchUsers = () => {
    // dispatch({ type: "FETCH_LOAD" });
   
     apiCall.GET("users");


   };
   const fetchCommon = () => {
    // dispatch({ type: "FETCH_LOAD" });
   
     apiCall.GET("common");


   };
   const fetchCompanies= () => {
    // dispatch({ type: "FETCH_LOAD" });
   
     apiCall.GET("companies");


   };

   
  useEffect(() => {

   
   if(state.user.loggedIn && state.users.firstLoad==false){
   
        fetchUsers();
        fetchCompanies();
        fetchCommon();
   
    }
  
   
   }, [dispatch, state]);
   
   
   
   
   
  
    if (state.user.loggedIn==false){
      return (<LoginForm/>);
     }

     const ADM=(state.common && state.common.me && state.common.me.role.is_super_admin===1?true:false);

     const IS_SIHTEERI=(state.common && state.common.me && state.common.me.role.is_sihteeri===1?true:false);
     const IS_KIRJANPITAJA=(state.common && state.common.me && state.common.me.role.is_kirjanpitaja===1?true:false);

  return (
    <BrowserRouter>
    <main>
      <InActivity/>
    <div className="App">
      <div className="left"><SideNavigation/></div>
      <div className="right">
      {ADM?

      <Switch>

        
      <Route path="/memo" exact children={<Memo/>}/>
      <Route path="/palkanlaskenta" exact>

<Palkanlaskenta/>
</Route>
<Route path="/lisapalkat" exact>

<PalkanlaskentaLisa/>
</Route>

<Route path="/netto" exact>

         <CreditCard/>
        </Route>
        <Route path="/persons" exact>

         <Persons/>
        </Route>
        <Route path="/persons/add" exact children={<AddPerson/>}/>
        <Route path="/persons/:id" exact children={<Person/>}/>
        <Route path="/persons/:id/taxes" exact children={<PersonTaxes/>}/>
        <Route path="/persons/:id/settings" exact children={<PersonSettings/>}/>
        <Route path="/persons/:id/loan" exact children={<PersonLoan/>}/>
        <Route path="/persons/:id/roles" exact children={<PersonRoles/>}/> 
        
        <Route path="/persons/:id/nettovahennykset" exact children={<PersonNettoVahennykset/>}/>
        

        <Route path="/persons/:id/bonukset" exact children={<PersonBonuses/>}/>
        


        <Route path="/palkanlaskenta/:id/:period" exact children={<PayWage/>}/>
        <Route path="/palkanlaskenta/:id/:period/:wage_id" exact children={<PayWage/>}/>
        <Route path="/asetukset" exact children={<Asetukset/>}/>


        <Route path="/pankki" exact children={<PankkiList/>}/>



        <Route path="/kayttajahallinta" exact children={<Kayttajahallinta/>}/>
        <Route path="/kayttajahallinta/:id" exact children={<KayttajaEditor/>}/>

        {/*}<Route path="/asetukset/bonuschart" exact children={<BonusChartMyyjat/>}/>
        <Route path="/asetukset/bonuschartosasto" exact children={<BonusChartOsasto/>}/>
       
        <Route path="/asetukset/bonuschartalaiset" exact children={<BonusChartAlaiset/>}/>
  */}
        <Route path="/yrityslainat" exact children={<YritysLainatAll/>}/>
        
        <Route path="/bonuslists" exact children={<BonusList/>}/>
        <Route path="/bonuses" exact children={<BonusesAll/>}/>
        <Route path="/bonuses/:id" exact children={<BonusChartEditor/>}/>
        <Route path="/raportit" exact children={<Palkkalaskelmat/>}/>
        <Route path="/raportithenkilot" exact children={<PalkkalaskelmatHenkilot/>}/>
        <Route path="/tapahtumat" exact children={<Tapahtumat/>}/>
        {<Route path="/" exact children={<Etusivu/>}/>}

        
      </Switch>:
    
      (IS_SIHTEERI?
        <Switch>
        <Route path="/omattiedot" exact children={<OmatTiedot/>}/>
        <Route path="/" exact children={<Etusivu/>}/>
        <Route path="/tapahtumat" exact children={<Tapahtumat/>}/>
        </Switch>
        :

        <Switch>
     <Route path="/" exact children={<Etusivu/>}/>
     <Route path="/palkanlaskenta" exact>

      <Palkanlaskenta/>

      </Route>
      <Route path="/palkanlaskenta/:id/:period" exact children={<PayWage/>}/>
      <Route path="/palkanlaskenta/:id/:period/:wage_id" exact children={<PayWage/>}/>
      <Route path="/raportit" exact children={<Palkkalaskelmat/>}/>
      <Route path="/raportithenkilot" exact children={<PalkkalaskelmatHenkilot/>}/>
      <Route path="/omattiedot" exact children={<OmatTiedot/>}/>
      </Switch>)
  
   
      
      }
     
    

      </div>
    </div>
    </main>
    </BrowserRouter>
  );
}

export default App;
