import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import InputDouble from "./form/InputDouble.js";

import InputSelect from "./form/InputSelect.js";
import { apiClient } from "../apiCall";
import {format} from "../commonFunctions";

import SettingPair from "./form/SettingPair.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";


import TopNav from "./TopNav.js";
import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";



  export const defaultPankkiData={
  
    data:null,
    loading:true,
    orginal:null
};


export default function Pankki(props) {


  const { state, dispatch } = useStore();

  const apiCall = apiClient(state, dispatch);	
 






const [PankkiData, setPankkiData] = useState({
    ...defaultPankkiData
    
});

useEffect(() => {
  let url="pankki/"+props.person_id;
  

  apiCall.GET_RAW(url, function(status,err_msgs=null,response=null){

	
    if(status==400){
  
      //setFolderData({...FolderData,errors:err_msgs})
    }else if(status==200){
    
      console.log("PANKKIDATA");
      console.log({...response.data});
        setPankkiData({...PankkiData,loading:false,data:{...response.data}})



    }




  });




  }, [props.person_id]);

  if(PankkiData.loading){
    return <div></div>
  }

  const changeInputValue=(name,value)=>{
props.changeInputValue(name,value);

  };

  const getGroup=(name,topic)=>{

    const saldo=(parseInt(PankkiData.data["saldo_"+name]) +  parseInt(props.data["pankki_"+name+"_pano"])-parseInt(props.data["pankki_"+name+"_otto"]));
   
    const saldoupd=<p className={"saldo new"+(saldo<0&&" highlight")}>Uusi saldo: {(saldo)} kpl</p>
  
    const uusisaldo=((props.data["pankki_"+name+"_otto"] !=0 || props.data["pankki_"+name+"_pano"])?saldoupd:null);


    return <tr>
        <td >
           <h5>{topic}</h5>
          </td>
          <td >
          <InputDouble onBlur={onBlur} onChange={changeInputValue} label="Otto" name={"pankki_"+name+"_otto"} data={props.data} edit={true}/>
          </td>
            <td >
          <InputDouble onBlur={onBlur} onChange={changeInputValue} label="Pano" name={"pankki_"+name+"_pano"} data={props.data} edit={true}/>
          </td>
          <td >

          <p className="saldo">Saldo: {PankkiData.data["saldo_"+name]} kpl</p>
          {uusisaldo}
          </td>
    
          </tr>
       
  }
const newSaldo=()=>{


    console.log("pano",props.data.pano);
   let newsaldo= parseFloat(PankkiData.data.saldo) +  parseFloat(props.data.pano)-  parseFloat(props.data.otto);

   return newsaldo;

}
  const onBlur=(name,value)=>{
    props.onBlur(name,value);
    
      };
      let uusisaldo=newSaldo();
  return (<div className='pankki'><h2>Pankki</h2>
   <div className="row">
          <div className="col">
          <InputDouble onBlur={onBlur} onChange={changeInputValue} label="Otto" name="otto" data={props.data} edit={true}/>
          </div>
            <div className="col">
          <InputDouble onBlur={onBlur} onChange={changeInputValue} label="Pano" name="pano" data={props.data} edit={true}/>
          </div>
          <div className="col">
          <InputText onBlur={onBlur} onChange={changeInputValue} label="Selite" name="pankkiselite" data={props.data} edit={true}/>
          </div>
    
    
    </div>
    
    <p className="saldo">Saldo: {format(PankkiData.data.saldo)} €</p>
   {(props.data.otto !=0 || props.data.pano!=0) ? <p className={"saldo new"+(uusisaldo<0&&" highlight")}>Uusi saldo: {format(uusisaldo)} €</p>:null}


    <table className="grouppi">{getGroup("km","Kilometrit")}
    {getGroup("paivarahat","Päivärahat")}
    {getGroup("puolipaivarahat","Puolipäivärahat")}</table>
  </div>);


}
