import React,{useState,useEffect} from "react";
import { apiClient } from "../apiCall";
import { useStore } from "../store/useStore";
export default function PrefillCredit(props) {

    const [prefills, setPrefills] = useState(
        []
        
    );

    const [results, setResults] = useState(
        []
        
    );
    const [last, setLast] = useState(
        ""
        
    );
    const { state, dispatch } = useStore();
      const apiCall = apiClient(state, dispatch);

    useEffect(() => {
            
            
        
        if(props.active && prefills.length==0){
           let url="prefill_ownbuys";
            apiCall.GET_RAW(url, function(status,err_msgs=null,response=null){
          
              
              if(status==400){
            
                //setFolderData({...FolderData,errors:err_msgs})
              }else if(status==200){
                setPrefills([...response.data]);
               
               // setJatkuvat({...Jatkuvat,items:[...response.data]});
             //  setMemoData({...MemoData,loading:false,data:response.data,orginal:[...JSON.parse(JSON.stringify(response.data))]})
          
              }
          
          
          
          
            });
          
          
        }
          
            }, [props.active]);

            useEffect(() => {
              
                if(prefills.length>0 && props.value.length>0){
                   var temp=[];
                    if(props.value!=last){
                        setLast("");
                    }
                   for(var i=0;i<prefills.length;i++){

                       let value= prefills[i].toLowerCase();
                        var min=props.value.length;

                        let cut=value.substr(0,min);
                        let cut2=props.value.toLowerCase().substr(0,min);

                        if(cut==cut2){
                            console.log(cut,cut2);
                            temp.push(prefills[i]);
                        }
                   }
                   setResults([...temp]);
                }else{
                setResults([]);

                }

            },[prefills,props.value]);

            const onSelect=(index)=>{
               
              
                if(props.update){
                    props.update(results[index]);
                    setResults([]);
                    setLast(results[index]);
                }
            };
            const RESULTS=results.map((result,index)=>(
            
            
                <div onClick={e=>onSelect(index)}key={index}>{result}</div>
                
                ));



            return (results.length>0?<div className='prefill'>

                {props.active && props.value!==last&&<div className='prefill-holder'>{RESULTS}</div>}
                
                </div>:null);
};