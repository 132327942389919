import React,{useState,useRef,useEffect,createRef} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import InputDouble from "./form/InputDouble.js";
import InputText  from "./form/InputText.js";

import InputBoolean  from "./form/InputBoolean.js";
import { apiClient } from "../apiCall";
import {format,formatSeason,getEnnakonPidatys,getTaxesForPeriod,getGlobalSettingsForPeriod, getUserSettingsForPeriod, getSettingValue,getBonus} from "../commonFunctions";
import AdditionSubstraction from "./AdditionSubstraction.js";
import Kulukorvaukset from "./Kulukorvaukset.js";
import PreviewTable from "./PreviewTable.js";
import TeamBonus from "./Teambonus.js";
import AlaisetBonus from "./form/AlaisetBonus.js";
import OmatAlaisetBonus from "./form/OmatAlaisetBonus.js";
import {getPalkkaObject,ulosOtonMaara,getTyel} from "../payWageFunctions";
import WageDisplay from "./WageDisplay.js"
import TopNav from "./TopNav.js";
import PreviewPalkka from "./PreviewPalkka.js";
import PreviewKaava from "./PreviewKaava.js";
import Pankki from "./Pankki.js";
import PreviewYritysLainat from "./PreviewYritysLainat.js";
import PreviewNettoVahennykset from "./PreviewNettoVahennykset.js";
import PreviousWageNav from "./PreviousWageNav.js";
import Yrityslainat from "./Yrityslainat.js";
import Period from "./form/Period.js";
import AllBonuses from "./AllBonuses.js";
import WageSheets from "./WageSheets.js";

import StatusLoader from "./StatusLoader.js";
import DateSelect from "./form/DateSelect.js";

import {
  
    useParams
  } from "react-router-dom";
import { findAllInRenderedTree } from "react-dom/test-utils";

 const defaultAdditionSubstraction={

	description:"",
	value:0.0,
	multiply:1

 };
export const defaultWageData = {
		period:null,
		ilmoitettu_myynti:0,
		laskutettu_myynti:0,
		provisio:0,
		koulutuspaivien_lkm:0,
		sairasloma_lkm:0,
		sairasloma_price:10,
		tuntipalkka:0,
		tunnit:0,
		activate_tuntipalkka:0,
	lisatiedot:null,
	memo:null,
		lomapalkka_lkm:0,
		lomapalkka_price:0,
		lomakorvaus_lkm:0,
		lomakorvaus_price:0,
		
		koulutuskilometrit:0,
		ennakko_provisio:0.0,
		additionsandsubstractions:[],
		kilometrit_lkm:0,
		paivarahat_lkm:0,
		paivarahatpuoli_lkm:0,
		lounaritmaara:null,
		maksuvapaa:0,
		isLomalla:0,
		isPuhelinEtuNolla:0,
		calculation:null,
		confirm:false,
		storing:false,
		confirmed:false,
		SairaslomaOpen:false,
		LomaPalkkaOpen:false,
		KoulutusPaivatOpen:false
		
	
};

export const defaultResultData = {
	calculation:null,
	loading:false,
	calculate_new:null
};
export const defaultPrevious = {
	data:null,
	loading:true,
};
export const defaultPreviousData = {
	id:null
};
export const defaultStatus={
	state:0
}
export const defaultViewMode = {
	mode:"laskenta"
};
export default function PayWage() {
	const { state, dispatch } = useStore();
	const {common}=state;

	const timerRef = useRef(null);


	let { id,period,wage_id } = useParams();

	console.log("PARAMS",useParams());
	const [ViewMode,setViewMode]= useState({
		...defaultViewMode
		
    });
	const [EnnakkoOpen,setEnnakkoOpen]=useState(false);
	const [Status,setStatus]= useState({
		...defaultStatus
		
    });
	
	
	const [PreviousData,setPreviousData]= useState({
		...defaultPreviousData
		
    });


	const [Previous, setPrevious] = useState({
		...defaultPrevious
		
    });


    const [WageData, setWageData] = useState({
		...defaultWageData
		
    });
	const [ResultData, setResultData] = useState({
		...defaultResultData
		
    });
	
	

	const apiCall = apiClient(state, dispatch);		
	



	  const apiResponse=(data,setOpens)=>{

		

	
		
	
		if(data){
			

			let SairaslomaOpenDefault=false;
			if(data.sairasloma_lkm!=0 || data.sairasloma_price!=0){
				SairaslomaOpenDefault=true;
			}

			let LomaPalkkaOpenDefault=false;
			if(data.lomapalkka_lkm!=0 || data.lomapalkka_price!=0){
				LomaPalkkaOpenDefault=true;
			}

			if(data.isLomalla==1){
				LomaPalkkaOpenDefault=true;
			}


			let KoulutusPaivatOpenDefault=false;
			if(data.koulutuspaivien_lkm!=0 || data.koulutuskilometrit!=0){
				 KoulutusPaivatOpenDefault=true;
			
			}
			
			
			setWageData({...data,SairaslomaOpen:SairaslomaOpenDefault,LomaPalkkaOpen:LomaPalkkaOpenDefault,KoulutusPaivatOpen:KoulutusPaivatOpenDefault});
			updatePreview({...data});
		}


	  };
	  const setStateLoading=()=>{

		setStatus({...Status,state:1});

	  };
	  const setStateLoadingError=()=>{

		setStatus({...Status,state:3});



			timerRef.current = setTimeout(() => {


				setStatus({...Status,state:0});



			}, 4000);


	  };

	  const saveOwnBuy=(id,value,label)=>{

		
		
		var url="";
		apiCall.ADD("creditcard/editsingle/"+id, {value:value},function(status,err_msgs=null){
	

		
			}).then(() => {
				//apiCall.GET('destinations');
				apiCall.GET("common");
				updatePreview({...WageData});
			});
		



			


		/*alert(id);*/
	  };





	  

	  const removeOwnBuy=(id)=>{

		//updatePreview({...WageData});
		var url="";
		apiCall.ADD("creditcard/remove/"+id, {},function(status,err_msgs=null){
	

		
			}).then(() => {
				//apiCall.GET('destinations');
				apiCall.GET("common");
				updatePreview({...WageData});
			});
		



			


		/*alert(id);*/
	  };
	  const setStateLoadingFinished=()=>{

		//setStatus({...Status,state:2});

		clearTimeout(timerRef.current);

		

		timerRef.current = setTimeout(() => {


			setStatus({...Status,state:2});



			timerRef.current = setTimeout(() => {


				setStatus({...Status,state:0});



			}, 2000);




		}, 1000);





		
		
	  };
	  useEffect(() => {
		// Clear the interval when the component unmounts
		return () => clearTimeout(timerRef.current);
	  }, []);
	  useEffect(() => {
		
		//if(common.current_period==null){ return; }
		
		


		fetch( config.apiUrl+"/persons/"+id+"/wagesconfirmed/"+period,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setPrevious({data:(data),loading:false}))

	  }, [id]);
	

	






	useEffect(() => {
		
		//if(common.current_period==null){ return; }
		
		var url=config.apiUrl+"/persons/"+id+"/wage/"+period;
		if(wage_id){

			
			url+="/"+wage_id;

		}

	
		fetch(url ,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data =>apiResponse(data,true))

	  }, []);
	
	
	  
    const updateWageData=(name,value)=>{
        setWageData({...WageData,[name]:value})
    };
	
	const loadPrevious=(previous_id)=>{
		alert("PREVV"+previous_id);

		//<WageDisplay  id={WageData.id}/>
		setPreviousData({id:previous_id});


			/*fetch( config.apiUrl+"/persons/"+id+"/wage/"+period_wanted,{headers: new Headers({
				'Authorization': 'Bearer '+state.user.token
				
			  })})
			  .then((res) => res.json())
			  .then(data =>previousApiResponse(data,true))*/
	
		





	};
	const saveDataAndConfirm=(data)=>{

		//setWageData({...WageData,"storing":true});
		

		let url="wages/"+data.id+"/save";
		///let data={...WageData};
		
		apiCall.ADD(url, data,function(status,err_msgs=null,response=null){
				
				
				if(status==400){
		
					//setFolderData({...FolderData,errors:err_msgs})
				}else if(status==200){
					console.log("CALC DATA",response.data);



					setResultData({...ResultData,calculation:{...response.data}});
				}
		
			}).then(() => {
				//apiCall.GET('destinations');
				
			});
		
		

	
	};


	const updatePreview=(data)=>{

		//setWageData({...WageData,"storing":true});
		

		let url="wages/"+data.id+"/preview";
		///let data={...WageData};
		
		apiCall.ADD(url, data,function(status,err_msgs=null,response=null){
				
				
				if(status==400){
		
					//setFolderData({...FolderData,errors:err_msgs})
				}else if(status==200){
					console.log("CALC DATA",response.data);



					setResultData({...ResultData,calculation:{...response.data.calculate},calculate_new:{...response.data.calculate_new}});
				}
		
			}).then(() => {
				//apiCall.GET('destinations');
				
			});
		
		

	
	};



	
	const tryToStore=(e)=>{

		setWageData({...WageData,"storing":true});
		let url="wages/"+WageData.id+"/confirm";
		///let data={...WageData};
		
		apiCall.ADD(url, {...WageData},function(status,err_msgs=null,response=null){
				
				
				if(status==400){
					
					//setFolderData({...FolderData,errors:err_msgs})
				}else if(status==200){



					console.log("CALC DATA",response.data);
					apiResponse(response.data);


					
				}
		
			}).then(() => {
				//apiCall.GET('destinations');
				
			});
		return;

	/*	let url="confirm/"+WageData.id;
		let data=getPalkkaObject(UserData,common,WageData);
		
		apiCall.ADD(url, data,function(status,err_msgs=null){
				
				alert(status);
				if(status==400){
		
					//setFolderData({...FolderData,errors:err_msgs})
				}else if(status==200){
		
			
				}
		
			}).then(() => {
				//apiCall.GET('destinations');
				
			});
	

*/
	};
	const makeZero=(e)=>{

		console.log("SAEND",WageData)

		let url="wages/"+WageData.id+"/makeaszero";
		let data={...WageData};
		var approve=window.confirm("Haluatko varmasti tallentaa nollana?");
		if(approve){

		
		apiCall.ADD(url, data,function(status,err_msgs=null,response){
			
				
				if(status==400){
		
					//setFolderData({...FolderData,errors:err_msgs})
				}else if(status==200){
	
					
				
					
			
				}
		
			}).then(() => {
				//apiCall.GET('destinations');
				window.location.reload();

				
			});

		}


	};
	const makeConfirmation=(e)=>{

			tryToSave(null,function(){
					
				setWageData({...WageData,"confirm":true})

			});
			//saveDataAndConfirm({...WageData});
			//setWageData({...WageData,"confirm":true})

	};
	const  RemoveAdditionSubstraction=(index)=>{

		
		let a=[...WageData.additionsandsubstractions];
		a.splice(index, 1);
		setWageData({...WageData,additionsandsubstractions:a})

	};
	const AddNewAdditionSubstraction=(e)=>{


		let a=[...WageData.additionsandsubstractions];
		
		a.push({...defaultAdditionSubstraction});
		
		setWageData({...WageData,additionsandsubstractions:a})

	};
	const  ChangeAdditionSubstraction=(name,value,index)=>{

		let a=[...WageData.additionsandsubstractions];

		a[index][name]=value;
		setWageData({...WageData,additionsandsubstractions:a})

	};
	const setYritysLainaLyhennys=(value)=>{

		/*let lainat=[...WageData.yrityslainat];
		console.log(lainat);
		lainat[index].era=value;*/
		console.log("-----");
		console.log(WageData.yrityslainan_lyhennys,value);
		setWageData({...WageData,yrityslainan_lyhennys:value})		

	};
	const changeInputValueAndUpdate =(name,value)=>{

		setWageData({...WageData,[name]:value});

		updatePreview({...WageData,[name]:value});

	};
	const changeInputValue = (name,value) => {
	
		//console.log("name-value",name,value);
        setWageData({...WageData,[name]:value})
        //dispatch({ type: "LOGINFORM_INPUT", key: name, value: value }, [dispatch]);
	};
	const onBlur = (name,value) => {
	
		/*fetch( config.apiUrl+"/persons/"+id+"/wage/preview/"+WageData.period)
		.then((res) => res.json())
		.then(data =>apiResponse(data));

		*/
		updatePreview({...WageData});
        //dispatch({ type: "LOGINFORM_INPUT", key: name, value: value }, [dispatch]);
	};
	
	

	if(!WageData.period){
			return (<div>Ladataan</div>);
	}



	const IS_LISA_PALKKA=(WageData.type=="main"?false:true);



	const SairaslomaOpen=(WageData.SairaslomaOpen===true);
	
	const LomaPalkkaOpen=(WageData.LomaPalkkaOpen===true);
	const KoulutusPaivatOpen=(WageData.KoulutusPaivatOpen===true);
	
const toggleSairaslomaOpen=()=>{

	
	setWageData({...WageData,SairaslomaOpen:!SairaslomaOpen})
};

const toggleLomaPalkkaOpen=()=>{

	
	setWageData({...WageData,LomaPalkkaOpen:!LomaPalkkaOpen})
};
const toggleKoulutusPaivatOpen=()=>{

	
	setWageData({...WageData,KoulutusPaivatOpen:!KoulutusPaivatOpen})
};

	const CURRENT_PERIOD=WageData.period;

	








/*
const netto_vahennykset=WageData.ownbuys.map(buy=>{

return <tr key={buy.id}><td>{buy.value} €</td><td>{buy.description}</td></tr>

});*/

//const netto_vahennykset_total=(WageData.ownbuys.length!==0?WageData.ownbuys.reduce((a,b)=>a.value+b.value):0);


	const changePeriod=(d)=>{


		
		
	};	

const tryToSave=(e,callback=null)=>{



	let url="wages/"+WageData.id;
	let data={...WageData};
	
	console.log("DATA TO SEND",data);
	setStateLoading();
	apiCall.ADD(url, data,function(status,err_msgs=null,response){
			console.log("TLI");
			
			if(status==400){
				setStateLoadingError();
				//setFolderData({...FolderData,errors:err_msgs})
			}else if(status==200){

				if(callback){
					setStateLoadingFinished();
					callback();
				}else{
					setStateLoadingFinished();
					console.log("UPD....."  ,response.data);
					 setWageData({...response.data})
				}
		
			}
	
		}).then(() => {
			//apiCall.GET('destinations');
			
		});
	


};
const unconfirm=()=>{

	//setWageData({...WageData,"storing":true});
		let url="wages/"+WageData.id+"/unconfirm";
		///let data={...WageData};
		
		apiCall.ADD(url, {...WageData},function(status,err_msgs=null,response=null){
				
				
				if(status==400){
					
					//setFolderData({...FolderData,errors:err_msgs})
				}else if(status==200){


					
					console.log("CALC DATA",response.data);
					apiResponse(response.data);


					
				}
		
			}).then(() => {
				//apiCall.GET('destinations');
				
			});
		return;


};
const older=(PreviousData.id!==null?<WageDisplay  id={PreviousData.id}/>:null);

	if(WageData.confirmed){

		return (<div><TopNav  /><WageDisplay unconfirm={unconfirm} print={true} id={WageData.id}/></div>)
	}
	if(WageData.confirm){
		
		return (<div><TopNav  /><WageDisplay store={tryToStore} topic={"Vahvista"} id={WageData.id}/></div>);
	}

	console.log("WA",WageData.calculationnew);
let rajasta_kaytetty=null;
	//ResultData.calculate_new
	if(ResultData.calculate_new){
let trate=ResultData.calculate_new.taxprogress_total/ResultData.calculate_new.taxes.addional_tax_rate_limit*100;
	 rajasta_kaytetty=Math.round(trate*100)/100;
	 rajasta_kaytetty=Math.min(100,rajasta_kaytetty);
	}


	const isKKPalkka=(WageData.calculation.role.roles_id==4?true:false);
	console.error("WWW",WageData);


const getLomainfo=()=>{
	if(ResultData.calculate_new){
		
	return ResultData.calculate_new.lomarahaperpaiva.debug.map(buy=>{

		return <div>{buy}</div>
		
		});

	}else{

		return null;
	}

}
const getLomainfoTotal=()=>{
	if(ResultData.calculate_new){

		return <div className="totalrows">
			<p><strong>Provisiokertymä yhteensä:</strong> {format(ResultData.calculate_new.lomarahaperpaiva.provisio_kertyma)}€</p>
			<p><strong>KK provisio:</strong> {format(ResultData.calculate_new.lomarahaperpaiva.kk_provisio)}€</p>
			<p class="result"><strong>Lomaraha per päivä:</strong> {format(ResultData.calculate_new.lomarahaperpaiva.day_provisio)}€</p>
			
			</div>
	}

	return null;


};
const formatDate=(d="")=>{
	let da=d.split("-");
		if(da.length==3){
			return da[2]+"."+da[1]+"."+da[0];
		}
		return d;
}
const taxinformation_ennakonpidatyksenalaiset=()=>{


	var rows=WageData.season.ennakot.map(tax=>{

		return <tr><td>{formatSeason(tax.period)} {formatDate(tax.paymentdate)}{tax.type!=="main"&&<span className="lisapalkka-text"> Lisäpalkka</span>}</td><td>{format(tax.ennakonpidatyksenalainen)} €</td></tr>
		
		});


	return <div className="smalltable"><h4>Ennakonpidätyksen alaiset tulot</h4><table><tr><th>Kausi</th><th>Ennakonpidätyksen alainen</th></tr>{rows}<tr><td></td><td>{format(WageData.season.ennakonpidatyksenalainen)} €</td></tr></table></div>
}
const getLomainfoTable=()=>{
	if(ResultData.calculate_new){
		
	return ResultData.calculate_new.lomarahaperpaiva.table.map(buy=>{

		return <tr><td>{buy.period}</td><td>{format(buy.provisio)} €</td><td>{buy.info}</td></tr>
		
		});

	}else{

		return null;
	}

}
	 const ennakkoOnChange=(name,value,index)=>{

		

		var id=(ResultData.calculate_new.previous_month_ennakko_array[index].id);
		apiCall.ADD("setwageennakkostate/"+id, {value:value},function(status,err_msgs=null){
	
		
		
		}).then(() => {
			updatePreview({...WageData});
			
		});
	



		


	/*alert(id);*/
 
		



	 };
const outputEnnakkoArray=(items)=>{

	const rows= items.map((item,index)=>{

		return <tr><td>{formatSeason(item.period)}</td><td>{format(item.ennakko_provisio)} €</td><td>
			
			<InputBoolean   onChange={ennakkoOnChange} data={item} index={index} name="ennakko_kuitattu" edit={true}/>
			</td></tr>
		
	});

	return <table><tbody>{rows}</tbody></table>

};
const getEnnakkoHolder=()=>{

var has_not_approved=0;
if(ResultData.calculate_new.previous_month_ennakko_array.length==0){
	return null;
}
for(var i=0;i<ResultData.calculate_new.previous_month_ennakko_array.length;i++){

	if(ResultData.calculate_new.previous_month_ennakko_array[i].ennakko_kuitattu==0){
		has_not_approved++;
	}
}
const ennakko_text=<span>Sinulla on {has_not_approved} kuittaamatonta ennakkoa</span>

return <div><h5 onClick={(e)=>setEnnakkoOpen(!EnnakkoOpen)}className={"ennakko-header"+(has_not_approved!=0?" red":"	")}>{ennakko_text} {EnnakkoOpen?"▼":"▶"}</h5>{EnnakkoOpen&&<table><tbody>{outputEnnakkoArray(ResultData.calculate_new.previous_month_ennakko_array)}</tbody></table>}</div>;
}

		const lomainfo=getLomainfo();
	return (
		<div className="mycontent wage-content medic">


				

				<div className="inner">
				<div className="box">
				<h2>{WageData.name} {formatSeason(WageData.period)} <div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div> { ResultData && ResultData.calculation && ResultData.calculation.canbeconfirmed?<div className="save-btn confirm" onClick={makeConfirmation}><span>Tallenna ja Vahvista</span></div>:null}{ ResultData && ResultData.calculation && ResultData.calculation.canbeconfirmed?(!IS_LISA_PALKKA&&<div className="save-btn confirm" onClick={makeZero}><span>Tallenna Nollana</span></div>):null}<StatusLoader state={Status.state}/></h2>
				<PreviousWageNav isLisapalkka={IS_LISA_PALKKA} period={WageData.period} loadPrevious={loadPrevious} items={Previous}/>
				
				<div class="both">
				<div class="left">
				<div className="form-blocks">



					
					<div className="form-block">
					{IS_LISA_PALKKA&&<div className="row cols-4">
						<div className="col">

						<DateSelect edit={true} day_min={2} day_max={15} disable_month={true} disable_year={true}  name="paymentdate" label="Maksupäivä" onChange={changeInputValue} data={WageData} />


						</div>
					</div>}
					
					{(isKKPalkka===false?
					<div className="row cols-4">
					
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue} label="Ilmoitettu myynti" name="ilmoitettu_myynti" data={WageData} edit={true}/>
					</div>

					<div className="col">
						<InputDouble onBlur={onBlur} onChange={changeInputValue} name="laskutettu_myynti" label="Laskutettu myynti" data={WageData} edit={true}/>
					</div>
					<div className="col">
							<InputDouble onBlur={onBlur}v onChange={changeInputValue}  label="Provisio" name="provisio" data={WageData} edit={true}/>
					</div>

					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Ennakko provisio" name="ennakko_provisio" data={WageData} edit={true}/>

							
					</div>
					
					
				</div>	

:null)}


{(WageData && WageData.id && WageData.has_pankki) && <Pankki changeInputValue={changeInputValue} onBlur={onBlur} person_id={id} data={WageData} />}

{ResultData.calculate_new && ResultData.calculate_new.previous_month_ennakko && ResultData.calculate_new.previous_month_ennakko.ennakko_provisio!=0  ?<p className="ennakko-info">Edellisen palkan {formatSeason(ResultData.calculate_new.previous_month_ennakko.period)+" ennakkoprovisio oli: "+format(ResultData.calculate_new.previous_month_ennakko.ennakko_provisio)} €</p>:null}
{ResultData.calculate_new  && ResultData.calculate_new.previous_month_ennakko_array && ResultData.calculate_new.previous_month_ennakko_array.length!=0&&<div>{getEnnakkoHolder()}</div>}	
		
				<h4>Kulukorvaukset</h4>
				<div className="row col-4">
					
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Kilometrien lkm" name="kilometrit_lkm" data={WageData} edit={true}/>
					</div>
						<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Päivärahat lkm" name="paivarahat_lkm" data={WageData} edit={true}/>
					</div>
					<div className="col">
					<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Puolipäivärahat lkm" name="paivarahatpuoli_lkm" data={WageData} edit={true}/>
					</div>
				
					<div className="col">
						{WageData.lounaritActive?
					<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lounasetelien määrä" name="lounaritmaara" data={WageData} edit={true}/>
							:null}
					</div>
					
				</div>
				
				


					{((WageData.koulutuspaivatActive || WageData.koulutusKmActive)?<div>	
				<h4 className={(KoulutusPaivatOpen?"arrow open":"arrow closed")} onClick={toggleKoulutusPaivatOpen}>Koulutuspäivät</h4>

{true?
				
				<div className="row"  className={"row"+(KoulutusPaivatOpen?" open":" closed")}>
				
				{WageData.koulutuspaivatActive?
				<div className="col">
				<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Koulutuspäivien lkm" name="koulutuspaivien_lkm" data={WageData} edit={true}/>
				</div>
				:null}
				{WageData.koulutusKmActive?
				<div className="col">
				<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Koulutus kilometrit" name="koulutuskilometrit" data={WageData} edit={true}/>
				
				</div>
				:null}
				
					
				

			</div>:null}

			</div>:null)}
			
		<h4><InputBoolean label="Onko tuntipalkollinen?"  onChange={changeInputValueAndUpdate} data={WageData} name="activate_tuntipalkka" edit={true}/></h4>
		{WageData.activate_tuntipalkka?<div className="row open">
			<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Tuntipalkka €/h" name="tuntipalkka" data={WageData} edit={true}/>
			</div>
			<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Tunnit kpl" name="tunnit" data={WageData} edit={true}/>
			</div>
		</div>:null}
			

			<h4 className={(SairaslomaOpen?"arrow open":"arrow closed")}  onClick={toggleSairaslomaOpen}>Sairaslomapalkka</h4>
				<div className={(SairaslomaOpen?"row open":"row closed")}>
					
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Sairasloma päiviä" name="sairasloma_lkm" data={WageData} edit={true}/>
					</div>

					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Sairasloma €/pvm" name="sairasloma_price" data={WageData} edit={true}/>
					</div>
					
					
				</div>

				<h4 className={(LomaPalkkaOpen?"arrow open loma-row":"arrow closed")}  onClick={toggleLomaPalkkaOpen}>Lomapalkka</h4>
				<div className={(LomaPalkkaOpen?"row open loma-row":"row closed")}>
					
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lomapalkka" name="lomapalkka_lkm" data={WageData} edit={true}/>
					</div>

					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lomapalkka €/pvm" name="lomapalkka_price" data={WageData} edit={true}/>
					</div>
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lomakorvaus" name="lomakorvaus_lkm" data={WageData} edit={true}/>
					</div>

					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lomakorvaus €/pvm" name="lomakorvaus_price" data={WageData} edit={true}/>
					</div>
					<div className="infos">

						{lomainfo}
						<table><tr><th>Kausi</th><th>Provisio</th><th>Info</th></tr>{getLomainfoTable()}
						
						</table>

						{getLomainfoTotal()}
					</div>
					
				</div>
				{WageData.type=="main"&&<div><h4>Nollaukset</h4>
				<div className="row open">
					
				<div className="col col-new-row" >

<InputBoolean label="Nollaa pohjapalkka?"  onChange={changeInputValueAndUpdate} data={WageData} name="isLomalla" edit={true}/>


</div>	
<div className="col col-new-row" >

<InputBoolean label="Nollaa puhelinetu?"  onChange={changeInputValueAndUpdate} data={WageData} name="isPuhelinEtuNolla" edit={true}/>


</div>	

					</div>	</div>}	

				<div className="yrityslainat-editor">
				<h4>Yrityslainat (lyhennettävät ajanjaksolla)</h4>
					<div className="row">
						<div className="col smalltable">
							<Yrityslainat onBlur={onBlur} setYritysLainaLyhennys={setYritysLainaLyhennys} data={WageData.yrityslainat} value={WageData.yrityslainan_lyhennys} default={WageData.oletus_lyhennys}/>
						</div>
					</div>	
				</div>
				
				{WageData.hasUlosotto?
				<div><h4>Ulosotto (maksuvapaa)</h4>
				<div className="row">
					<div className="col ">
				<InputBoolean  onChange={changeInputValueAndUpdate} data={WageData} name="maksuvapaa" edit={true}/>
					</div>
				</div></div>:null}
					{!IS_LISA_PALKKA&&
				<div className="provision-editor">
					<h4>Provision lisäys/vähennys</h4>
					<div className="smalltable">
					<AdditionSubstraction onBlur={onBlur} palkkarows={[]} onChange={ChangeAdditionSubstraction} data={WageData}  remove={RemoveAdditionSubstraction} add={AddNewAdditionSubstraction}/>
					
			

					</div>

				</div>
}


			





				


						</div>

						<div>{ResultData.calculation!==null && ResultData.calculation.bonuses_temp?<AllBonuses data={ResultData.calculation.bonuses_temp}/>:null}
</div>

<div className="smalltable">{ResultData.calculation!==null?<PreviewNettoVahennykset allowedit={true} onsave={saveOwnBuy} onremove={removeOwnBuy} topic="Netto vähennykset" data={ResultData.calculation.netto_vahennykset}/>
				:null}</div>



				{ResultData.calculate_new?		
<div className="tax-progress">
	<div className="limit"><span className="zero">0 €</span><span className="raja">{ResultData.calculate_new.taxes.addional_tax_rate_limit} €</span></div>				
	<div className="track">
		<div className="fill" style={{width:rajasta_kaytetty+"%"}}></div>
	</div>
	{taxinformation_ennakonpidatyksenalaiset()}				
					

</div>:null}



<div className="textarearow lisatiedot">
	<InputText  useAsTextArea={true} label="Lisätiedot" onChange={changeInputValue} data={WageData} name="lisatiedot" edit={true}/>
</div>

<div className="textarearow memo">
	<InputText  useAsTextArea={true} label="Memo" onChange={changeInputValue} data={WageData} name="memo" edit={true}/>
</div>

<p>{WageData.lisatiedot}</p>
<p>{WageData.memo}</p>

						</div>





					</div>
					<div className="right right-laskenta">

				

				
	<ul className="palkka-nav">

	<li className={(ViewMode.mode==="laskenta"?"active":null)}><span  onClick={(e) => {
        setViewMode({mode:"laskenta"});
    	}




		} >Laskenta</span></li>
		<li className={(ViewMode.mode==="yhteenveto"?"active":null)}><span  onClick={(e) => {
        setViewMode({mode:"yhteenveto"});
    	}




		} >Yhteenveto</span></li>
		<li  className={(ViewMode.mode==="palkkalaskelma"?"active":null)}><span  onClick={
			(e) => {
				setViewMode({mode:"palkkalaskelma"});
				}
			
			} >Palkkalaskelma</span></li>
		
		</ul>
	

<div className="results-preview">
					
					{ResultData.calculate_new!==null && ViewMode.mode==="yhteenveto"?<WageDisplay topic={"Yhteenveto"} id={null}  simple="yhteenveto" data={ResultData.calculate_new}/>:null}

					{ResultData.calculate_new!==null && ViewMode.mode=="palkkalaskelma"?<WageDisplay topic={"Palkkalaskelma"} id={null}  simple="palkkalaskelma" data={ResultData.calculate_new}/>
				:null}


					{ResultData.calculate_new!==null && ViewMode.mode=="laskenta"?<WageSheets  calculation={ResultData.calculate_new}/>
				:null}

				{/*
					<hr/><p>tässä vanha</p>
					{ResultData.calculation!==null?<PreviewKaava topic="Tulos" extraclass="tulos" data={ResultData.calculation}/>
				:null}	
				{ResultData.calculation!==null && ResultData.calculation.bonuses_temp?<AllBonuses data={ResultData.calculation.bonuses_temp}/>:null}

				{ResultData.calculation!==null?<PreviewPalkka  topic="Laskenta" data={ResultData.calculation}/>:null}
					

				{ResultData.calculation!==null?<PreviewTable key_name='luontoisetu' topic="Luontoisedut" data={ResultData.calculation}/>:null}
				{ResultData.calculation!==null?<PreviewTable key_name='kulukorvaus' topic="Kulukorvaukset" data={ResultData.calculation}/>:null}
				{ResultData.calculation!==null?<PreviewNettoVahennykset topic="Netto vähennykset" data={ResultData.calculation.netto_vahennykset}/>
				:null}

					*/}




</div>


					</div>
					
					</div>
					</div></div>
			
<div className="inners forms" class="hidden">
			<div className="inner leftinner">
				
				<div className="box">
				
					
                       


				<form
					onSubmit={(e) => {
						e.preventDefault();
						e.stopPropagation();
						//tryLogin();
					}}
				>
				<div className="laskenta">
				

				
			
				
				<div className="row">
					
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue} label="Ilmoitettu myynti" name="ilmoitettu_myynti" data={WageData} edit={true}/>
					</div>

					<div className="col">
						<InputDouble onBlur={onBlur} onChange={changeInputValue} name="laskutettu_myynti" label="Laskutettu myynti" data={WageData} edit={true}/>
					</div>
					
					
					
				</div>	
				<div className="row">
					
					<div className="col">
							<InputDouble onBlur={onBlur}v onChange={changeInputValue}  label="Provisio" name="provisio" data={WageData} edit={true}/>
					</div>

					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Ennakko provisio" name="ennakko_provisio" data={WageData} edit={true}/>
					</div>
					
					
				</div>
				<h4>Kulukorvaukset</h4>
				<div className="row three">
					
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Kilometrien lkm" name="kilometrit_lkm" data={WageData} edit={true}/>
					</div>
						<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Päivärahat lkm" name="paivarahat_lkm" data={WageData} edit={true}/>
					</div>
					<div className="col">
					<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Puolipäivärahat lkm" name="paivarahatpuoli_lkm" data={WageData} edit={true}/>
					</div>
					<div className="col">
					<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lounasetelien määrä" name="lounaritmaara" data={WageData} edit={true}/>

					</div>
					
				</div>





				







			

				




					{true?
				<div className="row">
					
					
					
					<div className="col">
					<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lounasetelien määrä" name="lounaritmaara" data={WageData} edit={true}/>

					</div>
					<div className="col">
						
					</div>

				</div>
				:null}

<h4 className={(KoulutusPaivatOpen?"arrow open":"arrow closed")} onClick={toggleKoulutusPaivatOpen}>Koulutuspäivät</h4>

{true?
				
				<div className="row"  className={(KoulutusPaivatOpen?"row open":"row closed")}>
				<div className="col">
				<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Koulutuspäivien lkm" name="koulutuspaivien_lkm" data={WageData} edit={true}/>
				</div>
				<div className="col">
				<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Koulutus kilometrit" name="koulutuskilometrit" data={WageData} edit={true}/>
				
				</div>
				
					
				

			</div>:null}
				
 



				<h4 className={(SairaslomaOpen?"arrow open":"arrow closed")}  onClick={toggleSairaslomaOpen}>Sairaslomapalkka</h4>
				<div className={(SairaslomaOpen?"row open":"row closed")}>
					
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Sairasloma päiviä" name="sairasloma_lkm" data={WageData} edit={true}/>
					</div>

					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Sairasloma €/pvm" name="sairasloma_price" data={WageData} edit={true}/>
					</div>
					
					
				</div>

				<h4 className={(LomaPalkkaOpen?"arrow open":"arrow closed")}  onClick={toggleLomaPalkkaOpen}>Lomapalkka</h4>
				<div className={(LomaPalkkaOpen?"row open":"row closed")}>
					
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lomapalkka" name="lomapalkka_lkm" data={WageData} edit={true}/>
					</div>

					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lomapalkka €/pvm" name="lomapalkka_price" data={WageData} edit={true}/>
					</div>
					
					
				</div>

				
				<div className="row">
				<h4>Lomakorvaus</h4> 
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lomakorvaus" name="lomakorvaus_lkm" data={WageData} edit={true}/>
					</div>

					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lomakorvaus €/pvm" name="lomakorvaus_price" data={WageData} edit={true}/>
					</div>
					
					
				</div>




				<h4>Yrityslainat</h4>
				<div className="row">
					<div className="col smalltable">
						<Yrityslainat onBlur={onBlur} setYritysLainaLyhennys={setYritysLainaLyhennys} data={WageData.yrityslainat} value={WageData.yrityslainan_lyhennys} default={WageData.oletus_lyhennys}/>
					</div>
				</div>	
					





			
					<h4>Ulosotto</h4>
					<div className="col">

					<InputBoolean  onChange={changeInputValueAndUpdate} data={WageData} name="maksuvapaa" edit={true}/>
					</div>
				<div className="smalltable">
					<h4>Provision lisäys/vähennys</h4>
					<AdditionSubstraction palkkarows={[]} onChange={ChangeAdditionSubstraction} data={WageData}  remove={RemoveAdditionSubstraction} add={AddNewAdditionSubstraction}/>
					
				</div>
					
				<div>
				</div>
				

				
			
				</div>
					

				
				

				
				
					
				</form>
				</div>
			</div>


			<div className="inner rightinner">
				
				<div className="box">

				<div>

	

				{ResultData.calculation!==null?<PreviewKaava topic="Tulos" data={ResultData.calculation}/>
				:null}	
				{ResultData.calculation!==null && ResultData.calculation.bonuses_temp?<AllBonuses data={ResultData.calculation.bonuses_temp}/>:null}

				{ResultData.calculation!==null?<PreviewPalkka  topic="Laskenta" data={ResultData.calculation}/>:null}
					

				{ResultData.calculation!==null?<PreviewTable key_name='luontoisetu' topic="Luontoisedut" data={ResultData.calculation}/>:null}
				{ResultData.calculation!==null?<PreviewTable key_name='kulukorvaus' topic="Kulukorvaukset" data={ResultData.calculation}/>:null}
				{ResultData.calculation!==null?<PreviewNettoVahennykset topic="Netto vähennykset" data={ResultData.calculation.netto_vahennykset}/>
				:null}

			
				</div>	
				</div></div>
		</div></div>
	);
}
